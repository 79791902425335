/** @format */

import React, { useEffect, useState } from "react";
import { getApi } from "../../Repository/Api";

const Reviews = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    getApi({
      url: "api/v1/ContactDetails/viewContactDetails",
      setResponse: setData,
    });
  }, []);

  return (
    <div className="Review_Title_Container ">
      <h1>Client Reviews</h1>
      <p>
        We are very proud of the service we provide and stand by every product
        we carry. We work hard to address our client's needs and have them leave
        our spa loving their skin. That's why over {data?.data?.numOfReviews}{" "}
        people have given us a 5-star rating on Google!
      </p>
      <img src="/asessts/google-review.png" alt="" />
    </div>
  );
};

export default Reviews;
