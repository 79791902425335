/** @format */

import {
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
  galleryImg6,
  galleryImg7,
  galleryImg8,
  galleryImg10,
  galleryImg12,
  galleryImg13
} from "../assest";

export const pictures = [
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg7,
  galleryImg5,
  galleryImg6,
  galleryImg4,
  galleryImg8,
  galleryImg12,
  galleryImg10,
  galleryImg13
];

export const paymentCards = [
  "/Image/Rectangle 4431.png",
  "/Image/Rectangle 4432.png",
  "/Image/image 246.png",
  "/Image/image 247.png",
  "/Image/Mask group.png",
];

export const STAR = "/Image/72.png";
export const MAP_IMG = "/Image/image 211.png";

const MenuOptions = [
  {
    link: "/",
    title: "HOME",
  },
  {
    link: "/shop",
    title: "SHOP",
  },
  {
    link: "/services/services",
    title: "SERVICES",
  },
  {
    link: "/limited-deals",
    title: "Limited Time Offers",
  },
  {
    link: "/paymentplan",
    title: "PAYMENT PLANS",
  },

  {
    link: "/membership",
    title: "Membership",
  },
  {
    link: "/giftcards",
    title: "GIFT CARDS",
  },
  {
    link: "/checkIngredients",
    title: "CHECK INGREDIENTS",
  },
  {
    title: "ACNE QUIZ",
  },
  {
    link: "/gallery",
    title: "GALLERY",
  },
  {
    link: "/contact",
    title: "CONTACT",
  },
  {
    link: "/aboutus",
    title: "ABOUT US",
  },
];

export { MenuOptions };
