/** @format */
import {
  areolase,
  revepeel,
  cosmelan,
  dermapen,
  dmk,
  facereality,
  hydrafacial,
  JetPeel,
  kilgour,
  meditresse,
  Quanta_System,
  skinbetter,
  reshape,
} from "../../assest";
import Slider from "react-slick";

const Partners = () => {
  const partnersImg = [
    areolase,
    revepeel,
    cosmelan,
    dermapen,
    dmk,
    facereality,
    hydrafacial,
    JetPeel,
    kilgour,
    meditresse,
    Quanta_System,
    skinbetter,
    reshape,
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    draggable: false,
    swipe: false,
    pauseOnHover: false,
    swipeToSlide: false,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="MaxComponent our-partners">
      <h5 className="title">OUR PARTNERS</h5>

      <div className="slider-cont">
        <Slider {...settings}>
          {partnersImg?.map((i, index) => (
            <div className="swiper-slide-img-container" key={index}>
              <img src={i} alt="" className="sliding-img" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Partners;
