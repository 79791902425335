/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../Repository/Api";
import ServiceOrderCard from "../OrderCard/ServiceOrderCard";

const PastServiceOrder = ({ isSliced, heading, padded, isMore }) => {
  const [order, setOrder] = useState([]);
  const [addOnServicePresent, setAddOnServicePresent] = useState(false);
  const [regularServicePresent, setRegularServicePresent] = useState(false);

  const fetchHandler = () => {
    getApi({
      url: `api/v1/serviceOrders?serviceStatus=Done`,
      setResponse: setOrder,
    });
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    const isAnyAddOnServicePresent = order?.data?.some(
      (item) => item?.AddOnservicesSchema?.length > 0
    );
    setAddOnServicePresent(isAnyAddOnServicePresent);
  }, [order]);

  useEffect(() => {
    const isRegularServicePresent = order?.data?.some(
      (item) => item?.services?.length > 0
    );
    setRegularServicePresent(isRegularServicePresent);
  }, [order]);

  return (
    <div className={`${!padded && "user_product_order_container"}`}>
      <div className="user_product_order">
        {!order ? (
          <div className="Not-Found">
            <img src="/Image/out-of-stock.png" alt="" />
            <h5> You have no past service orders.</h5>
          </div>
        ) : (
          <>
            {!heading && <p className="title">Past Service</p>}
            {isSliced === true ? (
              <>
                {regularServicePresent === true ? (
                  <div className="service-upcoming-order">
                    {order?.data
                      ?.slice(0, 4)
                      ?.map((item) =>
                        item?.services?.map((i) => (
                          <ServiceOrderCard
                            img={i.serviceId?.images?.[0]?.img}
                            title={i?.serviceId?.name}
                            price={
                              i.memberprice
                                ? i.memberprice
                                : i?.serviceId?.price
                            }
                            orderId={item?.orderId}
                            time={item?.toTime}
                            membershipDiscount={item?.memberShip}
                            serviceId={i?.serviceId?._id}
                            isCancel={false}
                            isReschedule={false}
                            key={i?.serviceId?._id}
                          />
                        ))
                      )}
                  </div>
                ) : (
                  <div className="Not-Found">
                    <img src="/Image/out-of-stock.png" alt="" />
                    <h5> No regular services are present.</h5>
                  </div>
                )}
              </>
            ) : (
              <>
                {regularServicePresent === true ? (
                  <div className="service-upcoming-order">
                    {order?.data?.map((item) =>
                      item?.services?.map((i) => (
                        <ServiceOrderCard
                          img={i.serviceId?.images?.[0]?.img}
                          title={i?.serviceId?.name}
                          price={
                            i.memberprice ? i.memberprice : i?.serviceId?.price
                          }
                          orderId={item?.orderId}
                          time={item?.toTime}
                          membershipDiscount={item?.memberShip}
                          serviceId={i?.serviceId?._id}
                          isCancel={false}
                          isReschedule={false}
                          key={i?.serviceId?._id}
                        />
                      ))
                    )}
                  </div>
                ) : (
                  <div className="Not-Found">
                    <img src="/Image/out-of-stock.png" alt="" />
                    <h5> No regular services are present.</h5>
                  </div>
                )}

                {addOnServicePresent === true && (
                  <>
                    {" "}
                    <div className="title_account_second">AddOn Service's</div>
                    <div className="service-upcoming-order">
                      {order?.data?.map((item) =>
                        item?.AddOnservicesSchema?.map((i) => (
                          <ServiceOrderCard
                            img={i.addOnservicesId?.image}
                            title={i?.addOnservicesId?.name}
                            price={i?.addOnservicesId?.price}
                            orderId={item?.orderId}
                            time={item?.toTime}
                            membershipDiscount={item?.memberShip}
                            offerDiscount={item?.offerDiscount}
                            serviceId={i?.serviceId?._id}
                            isCancel={false}
                            isReschedule={false}
                            isView={false}
                            key={`SeviceIndex${i?.serviceId?._id}`}
                          />
                        ))
                      )}
                    </div>{" "}
                  </>
                )}
              </>
            )}

            {order?.data?.length > 4 && isMore && (
              <Link to="/past-orders">
                <button className="view_more_btn">
                  View More Appointments
                </button>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PastServiceOrder;
