/** @format */

import React from "react";
import HeroSection from "../../components/home/HeroSection";
import Services from "../../components/home/Services";
import Description from "../../components/home/Description";
import LatestNews from "../../components/home/LatestNews";
import Testimonials from "../../components/PaymentPlans/Testimonials";
import GallarySlider from "../../components/Sliders/GallarySlider";
import Partners from "../../components/Partners/Partners";
// import Awards from "../../components/Awards/Awards";
import Reviews from "../../components/Reviews/Reviews";
                                                                                                
const Home = () => {
  return (
    <div>
      <HeroSection />
      {/* Award section  */}
      {/*<Awards />*/}
      <Services />
      {/* <Services className={"Heading2"} /> */}
      <Reviews />
      <Testimonials />
      <div className="mb-14"></div>
      <Description />
      <Partners />
      <LatestNews />
      <GallarySlider />
    </div>
  );
};

export default Home;